import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { LoginForm } from '../Models/login-form';
import { Observable, of } from 'rxjs';
import { throwError } from 'rxjs/internal/observable/throwError';
import { map, catchError } from 'rxjs/operators';
import { GlobalService } from './SiteAuth/global.service';

// Mock For Rewards;

import { REWARDS, REWARDENTRIES, WEEKTASK, DASHBOARDCOUNT, CITY, POSITION, WEEK, CATEGORY, RATING } from "../Models/mock-entries";
import { WeekEnttries } from '../Models/WeekEntries';

const login = '/admin/login';
const reward = '/staff/getrewardslist';
const collagesData = '/staff/getcollageassigneddata';
const updateWinnerEntry = '/staff/updateentry';
const parameters = '/staff/ratingparameters';
const cityAPI = '/staff/getcitylist';
const tasksAPI = '/staff/moderatortasks';
const documentAPI = '/staff/campaigndocumentlist';
const campaignAPI = '/staff/campaigns';
const faqAPI = '/staff/campaignfaqlist';
const user = '/admin/getallusers';
const state = '/hub-admin/getallstates';
const template = '/hub-admin/gettemplates';
const brand = '/hub-admin/getallmenubrand';
const request = '/hub-admin/getallmenurequest';
const agentrequest = '/hub-admin/getagentrequestdetails';
const requestTrans = '/hub-admin/getmenutransactionsbyid';
const region = '/hub-admin/getallregion';
const assign = '/hub-admin/assigntoagent';
const reassign = '/hub-admin/reassigntoagent';
const notification = '/hub-admin/getallnotifications';
const removeNotification = '/hub-admin/removenotification';
const updatefcmtoken = '/hub-admin/updatefcmtoken';
const requestupdate = '/hub-admin/agentrequestupdate';
const bulkrecord = '/hub-admin/getbulkdetails';
const BULK_ASSIGNED_API = '/hub-admin/bulkassign';
const AUTHORIZATION_REPORT = '/hub-admin/getauthorizereport';

@Injectable({
  providedIn: 'root'
})

export class UserService {

  AUTHORIZATION_REPORT = '/hub-admin/getauthorizereport';
  AUTHORIZATION_2 = '/hub-admin/getauthorizepanel';
  AUTHORIZATION_SUBMIT_API = '/hub-admin/authorizesubmit';

  public CampaignId: number = 1;

  constructor(private http: HttpClient, private siteAuth: GlobalService) {
  }

  private getHeaders() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    });
  }

  submitRecords(assignRecord: any) : Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + this.AUTHORIZATION_SUBMIT_API;
    let body = assignRecord;
    return this.http.post<any>(apiUrl, body, { headers: headers })
      .pipe(
        map(
          response => {
            return response.data;
          }
        ),
        catchError(this.handleError)
      )
  }

  userLogin(LoginForm: LoginForm): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + login;
    let body = JSON.stringify({ LoginForm });
    return this.http.post<any>(apiUrl, body, { headers: headers })
      .pipe(
        map(
          response => {
            return response;
          }
        ),
        catchError(this.handleError)
      )
  }

  public get(url: any): Observable<any> {
    let apiUrl = this.siteAuth.apiHostUrl + url;
    let headers = this.getHeaders();
    return this.http.get<any>(apiUrl, { headers: headers });
  }

  getRewardsOfCampaign(): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + reward + '?campaign_id=' + this.CampaignId;

    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return response;
          }
        ),
        catchError(this.handleError)
      )
  }

  getRewardsEntriesData(): Observable<any> {
    return of(REWARDENTRIES);
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + collagesData;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return response;
          }
        ),
        catchError(this.handleError)
      )
  }

  getWeekEntriesData(): Observable<any> {
    // console.log(WeekEnttries);
    // return of(WEEKENTRIES);
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + collagesData;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return response;
          }
        ),
        catchError(this.handleError)
      )
  }

  updateWeekEntry(entry: WeekEnttries): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + updateWinnerEntry;
    let body = JSON.stringify(entry);
    return this.http.post<any>(apiUrl, body, { headers: headers })
      .pipe(
        map(
          response => {
            return response;
          }
        ),
        catchError(this.handleError)
      )
  }

  getTaskEntriesData(): Observable<any> {
    // return of(WEEKTASK);
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + tasksAPI;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return response;
          }
        ),
        catchError(this.handleError)
      )
  }

  getDashboardData(): Observable<any> {
    return of(DASHBOARDCOUNT)
  }

  getRelatedDocs(): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + documentAPI + '?campaign_id=' + this.CampaignId;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return response;
          }
        ),
        catchError(this.handleError)
      )
  }

  getFaQData(): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + faqAPI + '?campaign_id=' + this.CampaignId;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return response;
          }
        ),
        catchError(this.handleError)
      )
  }

  getAllCampaigns(): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + campaignAPI;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return response;
          }
        ),
        catchError(this.handleError)
      )
  }

  getCityData(): Observable<any> {
    //return of(CITY)
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + cityAPI;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return response;
          }
        ),
        catchError(this.handleError)
      )
  }

  getRatingEntries(): Observable<any> {
    return of(RATING)
  }

  getPositionData(): Observable<any> {
    return of(POSITION)
  }

  getWeeks(): Observable<any> {
    return of(WEEK)
  }

  getCategory(): Observable<any> {
    return of(CATEGORY)
  }

  getRatingParameter(): Observable<any> {
    //return of (RATING)
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + parameters;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return response.data;
          }
        ),
        catchError(this.handleError)
      )
  }

  getAllUsers(): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + user;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return response.data;
          }
        ),
        catchError(this.handleError)
      )
  }

  getAllStates(): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + state;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return response.data;
          }
        ),
        catchError(this.handleError)
      )
    }

    getAllTemplate() : Observable<any> {
      let headers = this.getHeaders();
      let apiUrl = this.siteAuth.apiHostUrl + template;
      return this.http.get<any>(apiUrl, { headers: headers })
        .pipe(
          map(
            response => {
              return response.data;
            }
          ),
          catchError(this.handleError)
        )
    }

    getAllNotification(UserId) : Observable<any> {
      let headers = this.getHeaders();
      let apiUrl = this.siteAuth.apiHostUrl + notification + '?user_id=' + UserId;
      return this.http.get<any>(apiUrl, { headers: headers })
        .pipe(
          map(
            response => {
              return response.data;
            }
          ),
          catchError(this.handleError)
        )
    }

    RemoveNotification(data) : Observable<any> {
      let headers = this.getHeaders();
      let apiUrl = this.siteAuth.apiHostUrl + removeNotification;
      let body = data;
      return this.http.post<any>(apiUrl, body, { headers: headers })
        .pipe(
          map(
            response => {
              return response.data;
            }
          ),
          catchError(this.handleError)
        )
    }

    getAllBrands() : Observable<any> {
      let headers = this.getHeaders();
      let apiUrl = this.siteAuth.apiHostUrl + brand;
      return this.http.get<any>(apiUrl, { headers: headers })
        .pipe(
          map(
            response => {
              return response.data;
            }
          ),
          catchError(this.handleError)
        )
    }


    getAllTemplateMenuRequest() : Observable<any> {
      let headers = this.getHeaders();
      let apiUrl = this.siteAuth.apiHostUrl + request +"?calendar_year="+2021;;
      return this.http.get<any>(apiUrl, { headers: headers })
        .pipe(
          map(
            response => {
              return response.data;
            }
          ),
          catchError(this.handleError)
        )
    }

    getAllTemplateMenuRequestByAgent() : Observable<any> {
      let headers = this.getHeaders();
      let apiUrl = this.siteAuth.apiHostUrl + agentrequest +"?calendar_year="+2021;
      return this.http.get<any>(apiUrl, { headers: headers })
        .pipe(
          map(
            response => {
              return response.data;
            }
          ),
          catchError(this.handleError)
        )
    }

    getAllTemplateMenuRequestTransaction(id: number) : Observable<any> {
      let headers = this.getHeaders();
      let apiUrl = this.siteAuth.apiHostUrl + requestTrans + "?menu_id="+id;
      return this.http.get<any>(apiUrl, { headers: headers })
        .pipe(
          map(
            response => {
              return response.data;
            }
          ),
          catchError(this.handleError)
        )
    }



    getAllRegions() : Observable<any> {
      let headers = this.getHeaders();
      let apiUrl = this.siteAuth.apiHostUrl + region;
      return this.http.get<any>(apiUrl, { headers: headers })
        .pipe(
          map(
            response => {
              return response.data.regions;
            }
          ),
          catchError(this.handleError)
        )
    }

    getBulkCount() : Observable<any> {
      let headers = this.getHeaders();
      let apiUrl = this.siteAuth.apiHostUrl + bulkrecord;
      return this.http.get<any>(apiUrl, { headers: headers })
        .pipe(
          map(
            response => {
              return response.data;
            }
          ),
          catchError(this.handleError)
        )
    }


    BulkAssignedRecords(bulkassignvalue, user_id): Observable<any[]> {
      debugger
      let headers = this.getHeaders();
      let apiUrl = this.siteAuth.apiHostUrl + BULK_ASSIGNED_API;
      let body = JSON.stringify({
        "bulkassignvalue": bulkassignvalue,
        "assigned_user" : user_id
      });
      return this.http.post<any>(apiUrl, body, { headers: headers })
        .pipe(
          map(
            response => {
              return <any[]>response.data;
            }
          ),
          catchError(this.handleError)
        )
    }



    AssignRecords(assignRecord: any) : Observable<any> {
      let headers = this.getHeaders();
      let apiUrl = this.siteAuth.apiHostUrl + assign;
      let body = assignRecord;
      return this.http.post<any>(apiUrl, body, { headers: headers })
        .pipe(
          map(
            response => {
              return response.data;
            }
          ),
          catchError(this.handleError)
        )
    }

    agentRequestUpdate(assignRecord: any) : Observable<any> {
      let headers = this.getHeaders();
      let apiUrl = this.siteAuth.apiHostUrl + requestupdate;
      let body = assignRecord;
      return this.http.post<any>(apiUrl, body, { headers: headers })
        .pipe(
          map(
            response => {
              return response.data;
            }
          ),
          catchError(this.handleError)
        )
    }

    ReassignRecords(assignRecord: any) : Observable<any> {
      let headers = this.getHeaders();
      let apiUrl = this.siteAuth.apiHostUrl + reassign;
      let body = assignRecord;
      return this.http.post<any>(apiUrl, body, { headers: headers })
        .pipe(
          map(
            response => {
              return response.data;
            }
          ),
          catchError(this.handleError)
        )
    }

    UpdateFcmToken(data: any) : Observable<any> {
      let headers = this.getHeaders();
      let apiUrl = this.siteAuth.apiHostUrl + updatefcmtoken;
      let body = data;
      return this.http.post<any>(apiUrl, body, { headers: headers })
        .pipe(
          map(
            response => {
              return response.data;
            }
          ),
          catchError(this.handleError)
        )
    }

  private handleError(response: any) {

    let errorMessage: any = {};
    // Connection error
    console.log(response);

    if (response.status === 0) {
      errorMessage = {
        success: false,
        status: 0,
        data: 'Sorry, there was a connection error occurred. Please try again.'
      };
    } else {
      errorMessage = response.error;
    }

    return throwError(errorMessage);
  }

}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './Components/layout/layout.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { EntriesModule } from './Components/Entries/entries.module';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteDataProvider } from './Shared/RouteDataProvider.provider';
import { TabsModule, ModalModule, RatingComponent } from 'ngx-bootstrap';
import { FormsModule } from '@angular/forms';
import { RatingModule } from "ngx-rating";
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AuthGuard } from './Shared/AuthGuard';
import { LayoutModule } from './Components/layout/layout.module';
import { LocationStrategy, HashLocationStrategy, AsyncPipe } from '@angular/common';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { CacheInterceptor } from './Service/Http/cache.interceptor';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { MessagingService } from './Shared/messaging.service';
import {DataTableModule} from "angular-6-datatable";

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    RatingComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LayoutModule,
    FormsModule,
    RatingModule,
    NgxUiLoaderModule,
    DataTableModule,
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    ToastrModule.forRoot({
      maxOpened: 1,
      autoDismiss: true
    }),
    BsDatepickerModule.forRoot(),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    RouteDataProvider, AuthGuard, MessagingService, AsyncPipe],

  bootstrap: [AppComponent]
})
export class AppModule { }

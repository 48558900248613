import { Injectable, Inject, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { HttpCacheService } from './HttpCacheService';
import { ToastrService } from 'ngx-toastr';
import { NgxLoadingService } from 'ngx-loading';
import { NgxUiLoaderService } from 'ngx-ui-loader';


@Injectable()
export class CacheInterceptor implements HttpInterceptor {

  constructor(private cacheService: HttpCacheService, public toasterService: ToastrService,   public ngxLoader: NgxUiLoaderService) { }

  loaderStart() {
    try {
      this.ngxLoader.start();
    } catch (error) {
      console.log(error);
    }
  }
  loaderDismiss() {
    try {
      // this.loadingCntr.dismiss();
      this.ngxLoader.stop();
    } catch (error) {}
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // pass along non-cacheable requests and invalidate cache
    if (req.method !== 'GET') {
      console.log(`Invalidating cache: ${req.method} ${req.url}`);
      this.cacheService.invalidateCache();

      return next.handle(req);
    }
    // attempt to retrieve a cached response
    const cachedResponse: HttpResponse<any> = this.cacheService.get(req.url);
    // return cached response
    if (cachedResponse) {
      let path = cachedResponse.url.split('/');
      let b = path[path.length -1].split("?")[0];
      let c = path[path.length - 1];
      if (b != 'getallnotifications') {
        console.log(path);
        console.log(`Returning a cached response: ${cachedResponse.url}`);
        console.log(cachedResponse);
        return of(cachedResponse);
      }

    }

    // send request to server and add response to cache
    return next.handle(req)
      .pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
            this.loaderStart();
            console.log(`Adding item to cache: ${req.url}`);
            this.cacheService.put(req.url, event);
            this.loaderDismiss();
          }
        }),
        catchError((err: any) => {
          console.log(err);
          if (err instanceof HttpErrorResponse) {
            switch (err.status) {
              case 401:
                this.toasterService.error("Un-Authorized Request", "Error", {
                  timeOut: 10000,
                });
                //this.router.navigate(['/']);
                window.location.href = '/';
              case 403:
                this.toasterService.error("Un-Authorized Request", "Error", {
                  timeOut: 10000,
                });
                //this.router.navigate(['/']);
                window.location.href = '/';
                break;
              case 404:
                this.toasterService.error(err.message, "Error", {
                  timeOut: 10000,
                });
                break;
              case 500:
                this.toasterService.error("Internal Server Error - Data is not saved", "Error", {
                  timeOut: 10000,
                });
                break;
            }
          }
          return of(err);
        })
      );
  }
}
